// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";


h1{
  color: #4FADA3;
}


.nav{
  li{
    text-transform:uppercase;
    letter-spacing:1px;
  }
}


.wafa_teaser{
  height:175px;
  min-height:175px;

  .btn{
    position:absolute;
    bottom:5px;
    right:5px;
  }
}


.footer{
  margin-top:50px;
  height:50px;
  background-color:#ddd;

  ul{
    text-transform:uppercase;
    padding-top:15px;
    padding-bottom:15px;
    list-style-type:none;

    li{
      margin-right:10px;
      &:last-child{
        margin-right:0px;
      }

    }
  }
}


.margin-bottom-25{
  margin-bottom:25px;
}

hr{
  width:700px;
}
